<template>
  <div class="box">
    <el-row :gutter="0">
      <el-col :span="12">
        <div class="left">
          1
        </div>
      </el-col>
      <el-col :span="12">
        <div class="right">
          <div class="bd">
            <el-input
              v-model="username"
              placeholder="请输入内容"
              prefix-icon="el-icon-user"
            />
            <el-input
              v-model="password"
              placeholder="请输入内容"
              show-password
              prefix-icon="el-icon-lock"
              @keyup.enter.native="submit()"
            />
            <el-button
              type="primary"
              @click="submit"
            >
              登录
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { login } from "../api/myclueApi.js";
export default {
  data() {
    return {
      username: "",
      password: "",
      refresh1: "",
    };
  },
  methods: {
    submit() {
      if (this.username === "") {
        this.$message({
          message: "请输入账户",
          type: "warning",
        });
      } else if (this.password === "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
      } else {
        login({ username: this.username, password: this.password })
          .then((res) => {
            console.log(res);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("refreshToken", res.data.refreshToken);
            sessionStorage.setItem("code", res.code);
            this.$message({
              message: "登录成功",
              type: "success",
            });
            this.$router.push({ path: "/" });
          })
          .catch((error) => {
            console.log(error);
            this.$message({
              message: error.data.message,
              type: "warning",
            });
          });
      }
    },
  },
};
</script>


<style scoped lang="less">
.box {
  width: 100%;
  height: 100vh;
  background: url("../../public/images/bg.jpg");
  background-size: 100% 100%;
  .right {
    height: 100vh;
    display: flex;
    align-items: center;
    color: #fff;
    .bd {
      background: url("../../public/images/bgbd.png");
      background-size: 100% 100%;
      padding: 70px 50px;
      ::v-deep .el-input {
        width: 300px;
        display: block;
        margin-top: 20px;
      }
      ::v-deep .el-input__inner {
        border: 0;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        color: #fff;
      }
      ::v-deep .el-button {
        width: 300px;
        border-radius: 10px;
        margin-top: 30px;
      }
    }
  }
}
</style>
